import type { IOptionsConfiguration } from '@manager/components/Group/Custom/OptionExerciseDetailsTable/types'

export interface IReviewConfig {
  option: number
  review: number
  months?: number
  days?: number
}

export interface IUseOptionsGeneratorOptions {
  onAddOption?: (
    index: number,
    config: IOptionsConfiguration,
    prevConfig: IOptionsConfiguration | undefined,
  ) => void
  onAddReview?: (index: number, config: IReviewConfig) => void
  onRemoveOption?: (index: number) => void
  onRemoveReview?: (index: number) => void
  maxIterations?: number
}

export const useOptionsGenerator = (options: IUseOptionsGeneratorOptions) => {
  const {
    onAddOption = () => {},
    onAddReview = () => {},
    onRemoveOption = () => {},
    onRemoveReview = () => {},
    maxIterations = Infinity,
  } = options

  let totalReviewIndexes = 0
  let totalOptionIndexes = 0

  return (configs: IOptionsConfiguration[]) => {
    let currentReviewIndex = 0
    let currentOptionIndex = 0

    const createReview = (index: number, config: IReviewConfig) => {
      onAddReview(index, config)
      currentReviewIndex++
    }

    const createOption = (
      config: IOptionsConfiguration,
      prevConfig: IOptionsConfiguration | undefined,
    ) => {
      let currentOptionReview = 1

      // For each year, add a review
      for (let y = 0; y < Number(config.years || 0); y++) {
        if (currentReviewIndex >= maxIterations) break // Break if total iterations reach the maximum limit
        createReview(currentReviewIndex, {
          option: currentOptionIndex + 1,
          review: currentOptionReview,
        })
        // Increment review number for each year
        currentOptionReview++
      }

      const months = Number(config.months ?? 0)
      const days = Number(config.days ?? 0)

      // If there are months or days, add a review, but don't increment review number
      if (months > 0 || days > 0) {
        createReview(currentReviewIndex, {
          option: currentOptionIndex + 1,
          review: currentOptionReview,
          months,
          days,
        })
      }

      onAddOption(currentOptionIndex, config, prevConfig)

      // Increment option number
      currentOptionIndex++
    }

    const removeReviews = () => {
      if (totalReviewIndexes > currentReviewIndex) {
        for (let i = totalReviewIndexes - 1; i >= currentReviewIndex; i--) {
          onRemoveReview(i)
        }
      }
    }

    const removeOptions = () => {
      if (totalOptionIndexes > currentOptionIndex) {
        for (let i = totalOptionIndexes - 1; i >= currentOptionIndex; i--) {
          onRemoveOption(i)
        }
      }
    }

    // Loop through each configuration
    for (let index = 0; index < configs.length; index++) {
      if (currentReviewIndex >= maxIterations) break // Break if total iterations reach the maximum limit

      const config = configs[index]

      // Loop through each number of options
      for (let n = 0; n < Number(config.number || 0); n++) {
        if (currentReviewIndex >= maxIterations) break // Break if total iterations reach the maximum limit

        const prevConfig: IOptionsConfiguration | undefined =
          configs[n === 0 ? index - 1 : index]
        createOption(config, prevConfig)
      }
    }

    removeReviews()
    removeOptions()

    totalReviewIndexes = currentReviewIndex
    totalOptionIndexes = currentOptionIndex
  }
}
